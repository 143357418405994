import {useEffect, useRef, useState} from "react";
import Markdown from "react-markdown";
import {faCircleUser, faICursor, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextareaAutosize from 'react-textarea-autosize';

import logo from './consultimo_fav_32x32.webp';
import {assistantCheck, assistantInit, assistantThreat} from "./Api";

/**
 *
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const OneMessage = ({message, typing, inError}) => {
    return (<div className={'message'}>
        {!message || message.role !== "user" ? <div>
            <img src={logo} alt="Logo"/>
        </div> : <div style={{
            color: "rgba(0, 0, 0, 0.25)",
            width: "28px",
            height: "28px",
            fontWeight: "bold",
            textAlign: "center",
            lineHeight: "28px",
            position: "absolute",
            left: "-42px"
        }}><FontAwesomeIcon icon={faCircleUser} size={'2xl'}/></div>}

        <div style={{
            fontWeight: "bold", fontSize: "14px", lineHeight: "32px"
        }}>{!message || message.role !== "user" ? "Marion" : "Vous"}</div>
        {message ? <Markdown>{message.content[0].text.value}</Markdown> : null}
        {typing ? <div><FontAwesomeIcon icon={faICursor} size={'lg'} fade/></div> : null}
        {inError ? <div>Oooops ! Une erreur s'est produite. Je n'ai pas pu traiter votre demande. Veuillez réessayer s'il vous plait.</div> : null}
    </div>)
}

export const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState(null);

    const [threatId, setThreatId] = useState(null);

    const [runId, setRunId] = useState(null);
    const [status, setStatus] = useState(null);

    const abortControllerRef = useRef(null)
    const timerIdRef = useRef(null);
    const [isPollingEnabled, setIsPollingEnabled] = useState(false);
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        assistantInit().then((data) => {
            setRunId(data.runId);
            setThreatId(data.threatId);
            setIsPollingEnabled(true);
        }).catch(() => {
            console.log('errroooorrr')
            setFailed(true);
        })
    }, []);

    useEffect(() => {
        window.parent.postMessage({
            state: 'succeeded',
            height: document.documentElement.scrollHeight
        }, 'https://www.consultimo.fr');
    }, [messages, message]);

    useEffect(() => {
        const pollingCallback = () => {
            if (abortControllerRef.current !== null) {
                abortControllerRef.current.abort()
            }

            abortControllerRef.current = new AbortController()

            assistantCheck(abortControllerRef.current.signal, threatId, runId).then((data) => {
                setStatus(data.status);

                if (data.status === 'completed') {
                    stopPolling();
                    setMessages(data.messages.data);
                    setIsPollingEnabled(false);
                } else if (data.status === 'in_progress') {
                    setMessages(data.messages.data);
                } else {
                    stopPolling();
                    setIsPollingEnabled(false);
                    setFailed(true);
                }
            })
        };

        const startPolling = () => {
            // Polling every 3 seconds
            timerIdRef.current = setInterval(pollingCallback, 1000);
        };

        const stopPolling = () => {
            clearInterval(timerIdRef.current);
        };

        if (isPollingEnabled) {
            startPolling();
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [isPollingEnabled]);

    const sendMessage = () => {
        assistantThreat(threatId, message).then((data) => {
            setStatus(data.status);
            setRunId(data.runId);
            setIsPollingEnabled(true);
            setMessages(data.messages.data);
        }).catch(() => {
            setFailed(true);
        });
    }

    return (<div style={{
        fontFamily: "Montserrat", fontSize: "16px", display: "flex", height: "100%", flexDirection: "column"
    }}>
        <div id={"messages"} style={{
            flex: "1 1 0%"
        }}>
            <div style={{height: "100%"}}>
                {messages.map(message => {
                    return message.content.length > 0 ? <OneMessage message={message}/> : null
                })}

                {isPollingEnabled ? <OneMessage typing/> : null}
                {failed ? <OneMessage inError/> : null}
            </div>
        </div>
        <div style={{
            position: "relative", borderRadius: "1rem", border: "2px solid rgb(155, 155, 155)",
            margin: '0 10px',
        }}>
            <TextareaAutosize disabled={isPollingEnabled} value={message} style={{
                width: "100%",
                border: "0",
                background: "transparent",
                padding: "10px 60px 10px 10px",
                lineHeight: "35px",
                fontSize: "16px",
                boxSizing: "border-box"
            }} minRows={1} maxRows={5} onChange={(e) => {
                setMessage(e.target.value);
            }} autoFocus onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    setMessage('');
                    setFailed(false);
                    sendMessage();
                }
            }}/>
            <button style={{
                background: "none",
                padding: "10px",
                position: "absolute",
                border: 0,
                right: '9px',
                top: "9px",
                cursor: "pointer"
            }} disabled={isPollingEnabled} onClick={() => {
                setMessage('');
                setFailed(false);
                sendMessage()
            }}><FontAwesomeIcon size={"xl"} icon={faPaperPlane}/>
            </button>
        </div>
    </div>)
}