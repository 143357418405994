import config from "./config.json";

export const assistantCheck = async (signal, threatId, runId) => {
    return fetch(config.hostApi + '/api/assisant/check', {
        signal: signal,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
            'threatId': threatId,
            'runId': runId
        })
    }).then((resp) => {
        if (!resp.ok) {
            throw Error();
        }

        return resp.json();
    });
}

export const assistantThreat = async (threatId, message) => {
    return fetch(config.hostApi + '/api/assisant/threat', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
            'threatId': threatId,
            'message': message
        })
    }).then((resp) => {
        if (!resp.ok) {
            throw Error();
        }

        return resp.json();
    });
}

export const assistantInit = async () => {
    return fetch(config.hostApi + '/api/assisant/init', {
        headers: {
            'Accept': 'application/json', 'Content-Type': 'application/json'
        }, method: "POST", body: JSON.stringify({})
    }).then((resp) => {
        if (!resp.ok) {
            throw Error();
        }

        return resp.json();
    });
}