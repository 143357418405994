import './App.css';
import {Chat} from "./Chat";

function App() {
  return (
    <div className="App">
        <Chat/>
    </div>
  );
}

export default App;
